:root {
  --main-color: #2a676b;
  --secondary-color: #e3c886;
  --font-color: #45443f;
}

$black: #45443f;
$white: #fff;
$main_black: #000000;

$primary_color: #2a676b;
$primary_color_light: #f2f6f6;
$secondary_color: #e3c886;
$warning_color: #f89406;
$danger_color: #dc3545;
$success-color: #51a351;

$border-grey: #90a9a6;
$border-green: #215154;

$grey: #cecece;
$grey_color: #8e8d8d;

$secondary_hsl_color: hsl(43, 62%, 71%, 0.7);

$primary_color_kids: #209cd7;
$secondary_color_kids: #ffcf0c;
