@import '../../styles/common';

SECTION.blk-icon {
  background: var(--data-main-color-general);
  padding: 1.5em;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: $white;

  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  min-height: 76px;
  @include mobile_tablet {
    padding-top: 2.5em;
    padding-bottom: 1.6em;
    @include font-size(11px);
  }
  @include tablet {
    padding-bottom: 2.8em;
  }
  UL {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mobile_tablet {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
    }
    LI {
      display: flex;
      align-items: center;
      IMG {
        min-width: 2.5em;
        height: 100%;
        margin: 0 16px;
      }
      SPAN {
        width: 7em;
        text-align: center;
      }
      @include mobile_tablet {
        flex-direction: column;
        flex-basis: 50%;
        align-items: center;
        margin-bottom: 0.5em;
        IMG {
          margin-bottom: 0.5em;
        }
      }
    }
  }
}
