@import '../../styles/common';
SECTION.tab-Newsletter {
  > h1 {
    margin-bottom: 40px;
    @include font-size(32px);
    font-weight: $font-weight-semi-bold;
    @include mobile {
      margin-bottom: 24px;
      margin-top: 24px;
      text-align: center;
      @include font-size(24px);
      text-transform: uppercase;
    }
    @include tablet {
      margin-bottom: 24px;
      margin-top: 24px;
      @include font-size(24px);
      text-transform: uppercase;
    }
  }

  .newsletter {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    .checkbox-button {
      cursor: pointer;
      display: block;
      @include font-size(14px);
      position: relative;
      margin-top: 0;
      margin-bottom: 0;
      input:checked + .checkbox-button__control:after {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: 3px;
        width: 10px;
        height: 10px;
        background-color: $primary_color;
        border-radius: 1px;
      }
      input:checked + .checkbox-button__control {
        border-color: $primary_color;
      }

      input {
        font-size: 1rem;
        line-height: 1.5;
        // padding: 11px 23px;
        border: 1px solid #dddddd;
        border-radius: 0;
        outline: 0;
        background-color: $primary_color;
      }
      &__label {
        font-weight: $font-weight-semi-bold;
        @include font-size(16px);
        a {
          display: inline;
        }
      }
      &__input {
        opacity: 0;
        position: absolute;
      }
      &__control {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 4px;
        vertical-align: middle;
        background-color: inherit;
        color: $white;
        border-radius: 1px;
        border: 2px solid #dddddd;
      }
      &__control {
        transform: scale(0.75);
      }
    }
  }
  .btn-primary {
    margin-top: 1rem;
    float: right;
    background: $primary-color;
    color: white;
    padding: 10px 0px;
    font-size: 16px;
    letter-spacing: 0.1em;
    width: 20%;
    margin-left: auto;
    margin-right: 12px;
    &:hover {
      background: #2a676bdb;
    }
  }
}
