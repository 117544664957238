.verify {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: left;
  background-color: $primary_color_light;
  color: $grey_color;
  font-weight: 600;
  font-size: 10px;
  padding: 0.5rem;
  border-radius: 1rem;
  .label {
    width: 100%;
    margin-bottom: 0.2rem;
  }
  img {
    width: 100%;
    object-fit: contain;
  }
}
