@import '../../styles/common';

.component-Picture {
  display: flex;
  flex-direction: column;

  .caption {
    @include mobile {
      font-size: 10px;
    }
  }
}
