.block-modal-carousel {
  &.active {
    display: flex;
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: none;
  justify-content: center;
  align-items: center;
  opacity: 1;
  z-index: 10000;
  @keyframes opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: opacity 1s ease-in-out;

  .block-modal-carousel-wrap {
    width: 100vw;
    position: relative;
    overflow: hidden;
    & > button.close {
      @media (max-width: 1025px) {
        top: 7vw;
        right: 7vw;
        width: 6vw;
        height: 6vw;
      }

      position: absolute;
      top: 1vw;
      right: 1vw;
      width: 2vw;
      height: 2vw;
      z-index: 10005;

      img {
        object-fit: contain;
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
          hue-rotate(211deg) brightness(106%) contrast(101%);
      }
    }

    .blk-carousel {
      .carousel-block {
        .slider-wrapper {
          UL.slider-styles {
            // width: 100vw;
          }
          // .slide-inner {
          //   .animated {
          //     height: 100% !important;
          //     picture {
          //       width: auto;
          //       height: 100%;
          //       img {
          //         width: 100% !important;
          //         height: 100% !important;
          //         object-fit: contain !important;
          //       }
          //     }
          //   }
          // }
        }
      }
    }

    @include mobile_tablet {
      DIV.slider-wrapper {
        overflow: unset !important;
      }

      .blk-carousel {
        padding: 5vw;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .carousel-block {
          height: 100% !important;
          max-height: unset;
          display: flex;
          justify-content: center;
          align-items: center;
          .slider-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            picture {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 90vw;
              img {
                width: 100% !important;
                height: 100% !important;
                object-fit: contain !important;
              }
            }
            .content-slide {
              bottom: unset !important;
              min-height: unset !important;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
