@import '../../styles/common';
SECTION.blk-modal {
  position: relative;
  z-index: 99;
  > .panel {
    max-height: 100%;
    overflow: scroll;
    @include mobile {
      min-width: 98% !important;
    }
  }
  .btn-close {
    width: 24px;
    height: 24px;
    opacity: 1;
    position: relative;
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
    &:hover {
      opacity: 1;
    }
  }
  .btn-close:before,
  .btn-close:after {
    position: absolute;
    left: 50%;
    top: 0;
    content: ' ';
    height: 24px;
    width: 2px;
    border-radius: 5px;
    background-color: #333;
  }
  .panel-header {
    display: flex;
    justify-content: flex-end;
    padding: 0 0 1em;
  }

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10001;
  }
  .mask-hidden {
    // display: none;
    visibility: hidden;
  }

  .panel {
    background-color: '#fff';
    box-sizing: border-box;
    box-shadow: 0px 1px 9px 2px #38383824;
    background: $white;
    padding: 1em;
    z-index: 10002;
    height: auto;
    width: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &.hidden {
      z-index: -1;
    }
  }
}
