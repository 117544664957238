// @import './variables';
// @import './mixins';
// // Document
// // 1. Correct the line height in all browsers.
// // 2. Prevent adjustments of font size after orientation changes in iOS.
// // 3. Change the default font family in all browsers.
// // 4. Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.
// // 5. Change the default tap highlight to be completely transparent in iOS.
// // 6. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
// // 7. Correct the line height in all browsers.
// //

// *,
// *::before,
// *::after {
//   box-sizing: border-box; // 4
// }
// :focus {
//   outline: 0;
// }
// html {
//   font-family: sans-serif; // 3
//   line-height: 1.2; // 7
//   // -webkit-text-size-adjust: 100%; // 6
//   // -webkit-tap-highlight-color: rgba($black, 0); // 5
//   font-size: 21px;
//   @media (max-width: 414px) {
//     font-size: convert-vw(16);
//   }
// }

// // Sections

// //
// // Remove the margin in all browsers.

// body {
//   margin: 0; // 1
//   font-family: $font-family-base;
//   font-size: $font-size-base;
//   font-weight: $font-weight-normal;
//   line-height: $line-height-base;
//   color: $body-color;
//   text-align: left; // 3
//   // background-color: $black; // 3
// }

// //
// // Render the `main` element consistently in IE.

// main {
//   display: block;
// }
// :link,
// :visited {
//   text-decoration: none;
// }
// //
// // Typography
// //

// // Remove top margins from headings
// //
// // By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
// // margin for easier control within type scales as it avoids margin collapsing.
// // stylelint-disable-next-line selector-list-comma-newline-after
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   margin: 0;
// }

// // Reset margins on paragraphs
// //
// // Similarly, the top margin on `<p>`s get reset. However, we also reset the
// // bottom margin to use `rem` units instead of `em`.
// p {
//   margin: 0;
// }
// // Grouping content

// // 1. Add the correct box sizing in Firefox.
// // 2. Show the overflow in Edge and IE.

// ol,
// ul {
//   list-style: none;
//   padding: 0;
//   margin: 0;
// }

// hr {
//   box-sizing: content-box; // 1
//   height: 0; // 1
//   overflow: visible; // 2
// }

// // 1. Correct the inheritance and scaling of font size in all browsers.
// // 2. Correct the odd `em` font sizing in all browsers.

// pre {
//   font-family: inherit; // 1
//   font-size: 1em; // 2
// }

// // Text-level semantics

// // Remove the gray background on active links in IE 10.

// a {
//   background-color: transparent;
//   color: inherit;
//   transition: $transition-base;
// }

// // 1. Remove the bottom border in Chrome 57-
// // 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.

// abbr[title] {
//   border-bottom: none; // 1
//   text-decoration: underline; // 2
//   text-decoration: underline dotted; // 2
// }

// //
// // Add the correct font weight in Chrome, Edge, and Safari.

// b,
// strong {
//   font-weight: bolder;
// }

// // 1. Correct the inheritance and scaling of font size in all browsers.
// // 2. Correct the odd `em` font sizing in all browsers.

// code,
// kbd,
// samp {
//   font-family: inherit; // 1
//   font-size: 1em; // 2
// }

// // Add the correct font size in all browsers.

// small {
//   font-size: 80%;
// }

// // Prevent `sub` and `sup` elements from affecting the line height in
// // all browsers.

// sub,
// sup {
//   font-size: 75%;
//   line-height: 0;
//   position: relative;
//   vertical-align: baseline;
// }

// sub {
//   bottom: -0.25em;
// }

// sup {
//   top: -0.5em;
// }

// // Embedded content

// // Remove the border on images inside links in IE 10.

// img {
//   border-style: none;
// }

// // Forms

// // 1. Change the font styles in all browsers.
// // 2. Remove the margin in Firefox and Safari.

// button,
// input,
// optgroup,
// select,
// textarea {
//   font-family: inherit; // 1
//   font-size: 100%; // 1
//   line-height: 1.15; // 1
//   margin: 0; // 2
// }

// // Show the overflow in IE.
// // 1. Show the overflow in Edge.

// button,
// input {
//   // 1
//   overflow: visible;
// }

// // Remove the inheritance of text transform in Edge, Firefox, and IE.
// // 1. Remove the inheritance of text transform in Firefox.

// button,
// select {
//   // 1
//   text-transform: none;
// }

// // Correct the inability to style clickable types in iOS and Safari.

// button,
// [type="button"],
// [type="reset"],
// [type="submit"] {
//   -webkit-appearance: button;
// }

// // Remove the inner border and padding in Firefox.

// button::-moz-focus-inner,
// [type="button"]::-moz-focus-inner,
// [type="reset"]::-moz-focus-inner,
// [type="submit"]::-moz-focus-inner {
//   border-style: none;
//   padding: 0;
// }

// // Restore the focus styles unset by the previous rule.

// button:-moz-focusring,
// [type="button"]:-moz-focusring,
// [type="reset"]:-moz-focusring,
// [type="submit"]:-moz-focusring {
//   outline: 1px dotted ButtonText;
// }

// //  Correct the padding in Firefox.

// fieldset {
//   padding: 0.35em 0.75em 0.625em;
// }

// // 1. Correct the text wrapping in Edge and IE.
// // 2. Correct the color inheritance from `fieldset` elements in IE.
// // 3. Remove the padding so developers are not caught out when they zero out
// //    `fieldset` elements in all browsers.

// legend {
//   box-sizing: border-box; // 1
//   color: inherit; // 2
//   display: table; // 1
//   max-width: 100%; // 1
//   padding: 0; // 3
//   white-space: normal; // 1
// }

// // Add the correct vertical alignment in Chrome, Firefox, and Opera.

// progress {
//   vertical-align: baseline;
// }

// // Remove the default vertical scrollbar in IE 10+.

// textarea {
//   overflow: auto;
// }

// // 1. Add the correct box sizing in IE 10.
// // 2. Remove the padding in IE 10.

// [type="checkbox"],
// [type="radio"] {
//   box-sizing: border-box; // 1
//   padding: 0; // 2
// }

// //
// // Correct the cursor style of increment and decrement buttons in Chrome.

// [type="number"]::-webkit-inner-spin-button,
// [type="number"]::-webkit-outer-spin-button {
//   height: auto;
// }

// // 1. Correct the odd appearance in Chrome and Safari.
// // 2. Correct the outline style in Safari.

// [type="search"] {
//   -webkit-appearance: textfield; // 1
//   outline-offset: -2px; // 2
// }

// // Remove the inner padding in Chrome and Safari on macOS.

// [type="search"]::-webkit-search-decoration {
//   -webkit-appearance: none;
// }

// // 1. Correct the inability to style clickable types in iOS and Safari.
// // 2. Change font properties to `inherit` in Safari.

// ::-webkit-file-upload-button {
//   -webkit-appearance: button; // 1
//   font: inherit; // 2
// }

// // Interactive

// // Add the correct display in Edge, IE 10+, and Firefox.

// details {
//   display: block;
// }

// // Add the correct display in all browsers.

// summary {
//   display: list-item;
// }

// // Add the correct display in IE 10+.
// template {
//   display: none;
// }

// // Add the correct display in IE 10.

// [hidden] {
//   display: none;
// }

// table {
//   border-collapse: collapse;
// }

// // Links

// a {
//   color: $link-color;
//   text-decoration: $link-decoration;
//   background-color: transparent; // Remove the gray background on active links in IE 10.

//   @include hover-focus() {
//     color: $link-hover-color;
//     text-decoration: $link-hover-decoration;
//   }
// }

// a:not([href]) {
//   color: inherit;
//   text-decoration: none;

//   @include hover-focus() {
//     color: inherit;
//     text-decoration: none;
//   }
// }

// dl,
// dd,
// dt {
//   margin: 0;
//   padding: 0;
// }

// // image
// img {
//   max-width: 100%;
// }

// @font-face {
//   font-family: 'Niveau Grotesk';
//   src: url('../../fonts/Niveau Grotesk Regular.otf');

//   // src: url('//db.onlinewebfonts.com/t/bf2b1fe95719d869893d359d33ebc2fc.eot?#iefix')
//   //     format('embedded-opentype'),
//   //   url('//db.onlinewebfonts.com/t/bf2b1fe95719d869893d359d33ebc2fc.woff2') format('woff2'),
//   //   url('//db.onlinewebfonts.com/t/bf2b1fe95719d869893d359d33ebc2fc.woff') format('woff'),
//   //   url('//db.onlinewebfonts.com/t/bf2b1fe95719d869893d359d33ebc2fc.ttf') format('truetype'),
//   //   url('//db.onlinewebfonts.com/t/bf2b1fe95719d869893d359d33ebc2fc.svg#Niveau Grotesk')
//   //     format('svg');
// }
/*bold version*/
@font-face {
  font-family: 'Niveau Grotesk';
  src: url('../../fonts/Niveau Grotesk Medium.otf'); /* IE9 Compat Modes */
  src: local('Niveau Grotesk'), local('Niveau Grotesk'),
    /* Duplicated name with hyphen */ url('../../fonts/Niveau Grotesk Medium.otf')
      format('truetype');
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'Niveau Grotesk';
  src: url('../../fonts/Niveau Grotesk Regular.otf');
  src: local('Niveau Grotesk'), local('Niveau Grotesk'),
    url('../../fonts/Niveau Grotesk Regular.otf') format('truetype');
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'Niveau Grotesk';
  src: url('../../fonts/Niveau Grotesk Light.otf'); /* IE9 Compat Modes */
  src: local('Niveau Grotesk'), local('Niveau Grotesk'),
    /* Duplicated name with hyphen */ url('../../fonts/Niveau Grotesk Light.otf')
      format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Timberline Regular';
  src: url('../../fonts/Timberline Regular.woff'); /* IE9 Compat Modes */
  src: local('Timberline Regular'), local('Timberline Regular'),
    /* Duplicated name with hyphen */ url('../../fonts/Timberline Regular.woff')
      format('truetype');
  font-display: swap;
}

body {
  color: #45443f;
  letter-spacing: 0.04em;
}
* {
  margin: 0;
  padding: 0;
  text-rendering: auto;
  font-family: 'Niveau Grotesk';
  box-sizing: border-box;
}
h1, h2, h3, h4, h5, h6{
  font-family: "Niveau Grotesk";
}
A {
  @include prefix(transition, (all 170ms));
  text-decoration: none;
  color: inherit;
}

IMG[src=''] {
  visibility: hidden;
  width: 0;
  height: 0;
}

IMG,
TABLE,
FIELDSET {
  border: 0;
}

OL,
UL {
  list-style-type: none;
}

LEGEND {
  display: none;
}

BUTTON,
INPUT {
  border-radius: 0;
  outline: 0 none;

  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
}

BUTTON {
  @include prefix(appearance, none);
  background: {
    color: transparent;
    image: none;
  }
  overflow: visible;
  cursor: pointer;
  border: 0 none;

  &:focus {
    outline: 0 none;
  }
}

TEXTAREA {
  resize: none;
}

HR {
  color: black;
  background-color: black;
  border: none;
}

MARK {
  color: inherit;
  background-color: transparent;
}

.primary-select {
  border-radius: 0 !important;
  &:focus-within,
  &:focus,
  &:hover {
    border-color: #2a676b !important;
    box-shadow: none !important;
  }
  .react-dropdown-select-dropdown {
    border: none !important;
  }
  .react-dropdown-select-item:hover,
  .react-dropdown-select-item-selected {
    background: #2a676b !important;
    color: white;
  }
}

.blk-filter-results {
  @include desktop {
    .row {
      .col-lg-3 {
        width: 22% !important;
      }
      .col-lg-9 {
        width: 78% !important;
      }
    }
  }
}

.fb_iframe_widget {
  iframe {
    z-index: 98 !important;
  }
}
