@import '../../styles/common';

.component-NavigationMobileBottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  transition: 0.2s all;
  background-color: $white;

  z-index: 99;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    padding: 5px 5px;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 0px;
        font-size: 10px;
        color: var(--main-color);

        img {
          width: 25px;
          height: 25px;
          object-fit: contain;
        }
      }
    }
  }

  .navigation-block-mobile {
    top: 0;
  }

  .speed-dial {
    & > button {
      opacity: 0;
    }
  }
}
