.utilities-form-control {
  @include mobile_tablet {
    gap: 2px;
  }
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;

  &.error {
    input,
    select,
    textarea,
    .select__control {
      border-color: red;
    }
  }

  label {
    @include mobile_tablet {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.1em;
    }
    font-weight: 400;
    font-size: 18.4453px;
    line-height: 21px;
    letter-spacing: 0.1em;
  }

  input {
    width: 100%;
    height: 46px;
    padding: 16px 24px;
    font-weight: 300;
    font-size: 12.2969px;
    line-height: 14px;
    letter-spacing: 0.1em;
    background: transparent;
    border: 0.768555px solid #d2d2d2;
  }

  .input-initials {
    position: relative;
    & > div:first-child {
      padding: 16px 0px;
      font-size: 12.2969px;
      line-height: 14px;
      letter-spacing: 0.1em;
      position: absolute;
      top: 0;
      left: 12.35px;
      height: 100%;
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    input {
      padding-left: 50px;
    }
  }

  select::-ms-expand {
    display: none;
  }

  select::-ms-expand {
    display: none;
  }

  .select {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 17.29px;
      height: 7.69px;
      background: url('/assets/images/checkoutv2-select.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      top: 20px;
      right: 20px;
      transform: translateY(0%);
      z-index: 11;
    }

    select {
      width: 100%;
      height: 46px;
      padding: 0px 24px;
      font-weight: 400;
      font-size: 12.2969px;
      line-height: 18px;
      letter-spacing: 0.1em;
      background: transparent;
      border: 0.768555px solid #d2d2d2;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';

      &:focus {
        outline: none;
      }
    }
  }

  textarea {
    width: 100%;
    padding: 16px 24px;
    font-weight: 300;
    font-size: 12.2969px;
    line-height: 14px;
    letter-spacing: 0.1em;
    background: transparent;
    border: 0.768555px solid #d2d2d2;
    &:focus {
      outline: none;
    }
  }

  .radio,
  .checkbox {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    cursor: pointer;
    input {
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
    span {
      font-size: 13px;
    }

    &.disabled {
      span {
        color: #d2d2d2;
      }
    }
  }

  .group {
    @include mobile_tablet {
      gap: 39px;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 67px;
    & > .group-item {
      width: 100%;
      flex: 1;
    }
  }

  .validate {
    position: absolute;
    bottom: -13px;
    left: 0;
    color: red;
    font-size: 10px;

    &.success {
      color: green;
    }
  }
}
