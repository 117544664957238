@import '../../styles/common';
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

SECTION.blk-product-detail {
  margin-bottom: 4em;
  @include mobile {
    margin-bottom: 1em;
  }

  .block-slider {
    height: auto;
    picture {
      img {
        width: 100%;
      }
    }
  }
  .box-shadow {
    box-shadow: 0px 4px 7px 1px #e8e7e7;
    padding-bottom: 1rem;
  }
  .favorite-box,
  .sale-box {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 1.5em;
    right: 1.5em;
    top: 0;
    img {
      width: 1.5em;
    }
    @include mobile {
      img {
        width: 1em;
      }
    }
  }
  .sale-box {
    justify-content: flex-end;
  }
  .sale-inf {
    width: 65px;
    height: 65px;
    background: $secondary_color;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    //border-radius: 0px;
    font-size: 20px;
    font-weight: $font-weight-normal;
    z-index: 1;
    border-radius: 50px;
    &.bonus {
      flex-direction: column;
      > span > span {
        font-size: 16px;
        font-weight: $font-weight-semi-bold;
      }
    }
    @include mobile {
      width: 40px;
      height: 40px;
      font-size: convert-rem(14);
      margin-left: 10px;
      margin-top: 5px;
    }
  }
  .sale-inf-kids {
    width: 65px;
    height: 65px;
    background: $secondary_color_kids;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 50px;
    font-size: 20px;
    font-weight: $font-weight-normal;
    z-index: 1;
    &.bonus {
      flex-direction: column;
      > span > span {
        font-size: 16px;
        font-weight: $font-weight-semi-bold;
      }
    }
    @include mobile {
      width: 40px;
      height: 40px;
      font-size: convert-rem(14);
    }
  }
  .block-name {
    margin-right: 5px;
    .product-brand-name {
      display: flex;
      align-items: center;
      gap: 0.5em;
      margin-bottom: 7px;
    }
    .brand-name {
      display: flex;
      align-items: center;

      text-transform: uppercase;
      font-weight: $font-weight-bold;
      // margin-bottom: 7px;
      color: #2a676b;
      @include font-size(17px);
      @include mobile {
        @include font-size(17px);
      }
    }
    .product-name {
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      // margin-bottom: 7px;
      color: #2a676b;
      @include font-size(17px);
      @include mobile {
        font-size: 1rem;
      }

      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 8px;
      @include mobile {
        gap: 0;
        margin-bottom: 0;
      }
      div {
        color: black;
        @include font-size(19px);
        // margin-bottom: 5px;
        @include mobile {
          font-size: 1rem;
        }
        font-weight: $font-weight-normal;
      }
    }
    .product-type {
      color: $grey_color;
      @include font-size(14px);
      @include mobile {
        margin-top: 0;
        margin-bottom: 0px;
      }
      font-weight: $font-weight-semi-bold;
    }
  }
  > H2 {
    @include font-size(40px);
    font-weight: $font-weight-light;
    margin-bottom: 25px;
    @include mobile {
      @include font-size(24px);
      margin-bottom: 20px;
    }
  }
  DIV.product-slide {
    img {
      object-fit: contain;
    }
    VIDEO {
      cursor: pointer;
    }
  }
  SECTION.detail-product {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    //overflow-x: hidden;
    @include desktop {
      height: auto;
    }
    padding: 32px;
    @include tablet {
      padding: 16px;
      margin-top: 8px;
    }
    @include mobile {
      padding: 10px 16px;
      padding-top: 0;
      //margin-top: 16px;
      box-shadow: none;
    }
    .head {
      align-items: flex-start;
    }
    .box-share {
      &__text-share {
        font-weight: $font-weight-semi-bold;
        @include font-size(17px);
      }
    }
    .wishlist-button {
      cursor: pointer;
    }
    .evaluate-box {
      display: block;
      cursor: pointer;
      UL {
        display: flex;
        flex-wrap: nowrap;
      }
      .star-icon {
        img {
          margin-right: 2px;
          height: 19px;
          width: 19px;
          @include mobile {
            height: 12px;
            width: 18px;
            margin-bottom: 10px;
          }
        }
      }
      .text-avg {
        @include font-size(18px);
        color: $primary_color;
        font-weight: $font-weight-semi-bold;
        margin-left: 7px;
        @include mobile {
          @include font-size(12px);
        }
      }
      p {
        color: $grey_color;
      }
      span {
        color: $primary_color;
      }
    }
    .box-evaluate-share {
      @include desktop {
        margin-top: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .main {
      .truncate-string {
        // overflow: hidden;
        // display: -webkit-box;
        // -webkit-line-clamp: 4;
        // -webkit-box-orient: vertical;
        // text-transform: none;
        // max-height: 85px;
        // overflow: hidden;

        /**Major Properties**/
        overflow: auto;
        line-height: 1rem;
        max-height: 4.2rem;
        font-size: 14px;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        overflow: hidden !important;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        > * {
          font-size: 14px !important;
        }
      }
      .truncate-overflow {
        --max-lines: 3;
        position: relative;
        max-height: 5.2em;
        overflow: hidden;
        padding-right: 1rem; /* space for ellipsis */
        > p {
          font-size: 14px;
        }
      }
      .truncate-overflow::before {
        position: absolute;
        content: '...';
        inset-block-end: 0; /* "bottom" */
        inset-inline-end: 0; /* "right" */
      }
      .truncate-overflow::after {
        content: '';
        position: absolute;
        inset-inline-end: 0; /* "right" */
        width: 1rem;
        height: 1rem;
        background: white;
      }
      @include desktop {
        //padding-bottom: 11rem;
      }
      @include mobile {
        //padding: 1.5em 0;
      }
      > P {
        font-size: 18px;
        @include mobile {
          font-size: 14px;
        }
      }
      .box-collapse {
        border-top: 1px solid rgb(143, 144, 144);
        padding-bottom: 14px;
        &__navigate {
          padding-top: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          transition: 0.3s;
          &__text-title {
            @include font-size(20px);
            font-weight: $font-weight-semi-bold;
            color: $primary_color;
          }
          .material-icons.kids {
            color: #209cd7;
          }
          .material-icons.beauty {
            color: #2a676b;
          }
          .material-icons {
            @include font-size(35px);
          }
        }
        .ReactCollapse--collapse {
          transition: height 300ms ease 0s;
        }
        .ReactCollapse--content {
          padding-top: 18px;
          .tag-description {
            color: rgb(143, 144, 144);
          }
        }
        &:hover {
          border-top: 1px solid rgb(163, 162, 162);
        }
      }
      UL.scent-group-box {
        //padding-bottom: 16px;
        //margin-bottom: 32px;
        LI {
          display: flex;
          align-items: flex-start;
          //margin-bottom: 16px;
          .scent-note {
            margin-left: 16px;
          }
          h4 {
            margin-bottom: 8px;
            font-size: 16px;
            font-weight: 600;
          }
          BUTTON {
            display: inline-block;
            padding: 0.3em 0.6em;
            border: 1px solid $border-green;
            margin-right: 0.5em;
            margin-bottom: 0.5em;
            color: $primary_color;
            font-size: 12px;
            text-transform: uppercase;
            &:hover {
              font-weight: $font-weight-normal;
              opacity: 0.8;
              transition: 0.2s;
            }
          }
        }
        .wrapper-img {
          width: 32px;
          height: 32px;
          text-align: center;
          IMG {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
      }
      .tag-translated-box {
        margin-bottom: 20px;
        UL {
          LI {
            padding: 5px 5px 5px 0;
            .title {
              display: inline-block;
              font-weight: $font-weight-bold;
              text-transform: capitalize;
            }
          }
        }
      }
    }
    .quantity-price-bag {
      @include desktop {
        //position: absolute;
        width: 100%;
      }
      .quantity-price-group {
        //padding: 1.5rem;
        //margin-top: 32px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: column;
        .size-box {
          .size {
            font-weight: 600;
            //margin-top: 1rem;
          }
          .variant-option-stock-count {
            color: $primary_color;
            font-weight: 600;
            font-size: 12px;
            margin-top: 1rem;
          }
        }
        @include mobile_tablet {
          // flex-wrap: wrap;
          .size-box {
            width: 100%;
            > div {
              width: 100%;
            }
          }
        }
        p {
          font-size: px;
          text-transform: uppercase;
          margin-bottom: 8px;
        }
        @include mobile {
          font-size: 12px;
        }
        .quantity {
          width: 50%;
          padding-right: 1rem;
          .variant-option-stock-count {
            color: red;
            font-weight: 600;
            font-size: 12px;
            margin-top: 1.2rem;
          }
        }

        .size-box {
          width: 50%;
          padding-right: 1rem;
          UL {
            display: flex;
            flex-wrap: wrap;
            margin-top: 0.5em;
            gap: 12px;
            LI {
              flex-basis: 25%; /* explanation below */
              button {
                width: 100%;
                padding: 8px 0;
                font-size: 16px;
                border: 1px solid $grey;
                @include mobile {
                  font-size: 14px;
                  margin: 0 8px;
                }
              }
              button.active {
                background: $primary_color;
                color: $white;
                border: 1px solid $primary_color;
              }
            }
          }
          .variant-select {
            @include font-size(14px);
            // min-height: 32px;
            margin-top: 8px;
            padding-left: 10px;
            width: 100%;
            max-width: 10rem;
            @include mobile {
              width: 100%;
            }
            .react-dropdown-select-content > span {
              font-weight: $font-weight-semi-bold;
            }
            & .react-dropdown-select-input {
              @include font-size(14px);
            }
            .custom-item {
              padding: 6px;
              display: flex;
              justify-content: flex-start;
              &:hover {
                background-color: $primary_color_light;
              }
              &.selected {
                background-color: $primary_color;
                color: white;
              }
              &.disabled {
                opacity: 0.6;
                &:focus,
                &:hover {
                  cursor: not-allowed;
                  transition: 0.3s;
                }
              }
            }
          }
        }
        .quantity-box {
          display: flex;
          align-items: center;
          input {
            border: none;
            text-align: center;
            @include font-size(20px);
            max-width: 3em;
          }
          button {
            width: 32px;
            height: 32px;
            border: 1px solid #e1e1e1;
            text-align: center;
            line-height: 0;
            &:hover {
              background: $primary_color;
              color: $white;
              border: 1px solid $primary_color;
            }
          }
        }
        .quantity-box-kids {
          display: flex;
          align-items: center;
          input {
            border: none;
            text-align: center;
            @include font-size(20px);
            max-width: 3em;
          }
          button {
            width: 32px;
            height: 32px;
            border: 1px solid #e1e1e1;
            text-align: center;
            line-height: 0;
            &:hover {
              background: $primary_color_kids;
              color: $white;
              border: 1px solid $primary_color_kids;
            }
          }
        }
      }
    }

    .box-button {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex: 4 1 auto;
      @include mobile_tablet {
        display: block;
      }
      BUTTON.add-to-bag {
        flex: 5;
        @include mobile_tablet {
          width: 100%;
        }
        background: $primary_color;
        border: 1px solid $primary_color;
        color: white;
        display: block;
        width: 100%;
        padding: 14px 0;
        margin: 5px;
        font-size: 18px;
        letter-spacing: 0.1em;
        position: relative;
        &:hover {
          background: white;
          color: $primary_color;
          border: 1px solid $primary_color;
          box-shadow: 1px 3px 3px gray;
          IMG:nth-child(1) {
            display: none;
          }
          IMG:nth-child(2) {
            display: inherit;
          }
        }
        IMG {
          width: 28px;
          display: inherit;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(-50%, -50%);
          @include mobile {
            transform: translate(-90%, -60%);
          }
        }
        IMG:nth-child(2) {
          display: none;
        }
      }
      BUTTON.buy-now {
        flex: 3;
        @include mobile_tablet {
          width: 100%;
        }
        background: $white;
        border: 1px solid $primary_color;
        color: $primary_color;
        display: block;
        width: 100%;
        padding: 14px 0;
        margin: 5px;
        font-size: 18px;
        letter-spacing: 0.1em;
        position: relative;
        &:hover {
          background: $primary_color;
          color: $white;
          border: 1px solid $primary_color;
          box-shadow: 1px 3px 3px gray;
          IMG:nth-child(1) {
            display: none;
          }
          IMG:nth-child(2) {
            display: inherit;
          }
        }

        IMG {
          width: 28px;
          display: inherit;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(-50%, -50%);
          @include mobile {
            transform: translate(-90%, -60%);
          }
        }
        IMG:nth-child(2) {
          display: none;
        }
      }
      BUTTON.out-of-stock {
        flex: 1 1 auto;
        @include mobile_tablet {
          width: 100%;
        }
        background: $grey_color;
        border: 1px solid $secondary_color;
        color: $secondary_color;
        display: block;
        width: 100%;
        padding: 14px 0;
        margin: 5px;
        font-size: 18px;
        letter-spacing: 0.1em;
        position: relative;
      }
      BUTTON.btn-add-wish {
        flex: 4 1 auto;
        @include mobile_tablet {
          width: 100%;
          margin-left: 0;
          margin-top: 10px;
        }
        margin-left: 5px;
        @include desktop {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        border: 1px solid $primary_color;
        padding: 10px 14px;
        color: $primary_color;
        font-weight: $font-weight-semi-bold;
        transition: 0.2s all;
        font-size: 18px;
        @include mobile {
          padding: 10px 12px;
        }
        @include mobile_tablet {
          IMG {
            float: right;
          }
        }
        span {
          white-space: nowrap;
        }
        I {
          margin-left: 1em;
          margin-right: 0.2em;
          color: $grey;
        }
        &.wish-list {
          transition: 0.2s all;
          background: $white;
          color: $primary_color;
          border: 1px solid $secondary_color;
        }
      }
      BUTTON.kids-button {
        background: $primary_color_kids;
        border: 1px solid $primary_color_kids;
        border-radius: 30px;
        &:hover {
          background: white;
          color: $primary_color_kids;
          border: 1px solid $primary_color_kids;
          box-shadow: 1px 3px 3px gray;
          IMG:nth-child(1) {
            display: none;
          }
          IMG:nth-child(2) {
            display: inherit;
          }
        }
        IMG {
          width: 28px;
          display: inherit;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(-50%, -50%);
        }
        IMG:nth-child(2) {
          display: none;
        }
      }
      BUTTON.kids-buy-now {
        flex: 3;
        @include mobile_tablet {
          width: 100%;
        }
        background: $white;
        border: 1px solid $primary_color_kids;
        border-radius: 30px;
        color: $primary_color_kids;
        display: block;
        width: 100%;
        padding: 14px 0;
        margin: 5px;
        font-size: 18px;
        letter-spacing: 0.1em;
        position: relative;
        &:hover {
          background: $primary_color_kids;
          color: $white;
          border: 1px solid $primary_color_kids;
          box-shadow: 1px 3px 3px gray;
          IMG:nth-child(1) {
            display: none;
          }
          IMG:nth-child(2) {
            display: inherit;
          }
        }

        IMG {
          width: 28px;
          display: inherit;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(-50%, -50%);
        }
        IMG:nth-child(2) {
          display: none;
        }
      }
    }
    BUTTON.disabled-button {
      background: gray !important;
      border: 1px solid gray !important;
      color: white !important;
    }

    .ic-wishlist {
      max-height: 22px;
    }

    DIV.slider-wrapper {
      height: auto;
    }

    .slide-styles img {
      height: 100% !important;
    }
    @include mobile {
      .slide-styles {
        height: 45vh;
        overflow: hidden;
      }
      .slide-styles picture {
        height: 45vh !important;
        width: auto !important;
      }
    }
  }

  .accordion-container {
    margin: 1.5rem 0px;
    .accordion-details > UL > LI {
      margin: 8px;
    }
  }

  .price-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    @include mobile {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    //@include mobile {
    //  flex-direction: column;
    //}
    .price {
      width: 50%;
      padding-right: 1rem;
      @include mobile_tablet {
        width: auto;
      }
      p {
        font-weight: $font-weight-semi-bold;
        margin-bottom: 4px;
      }
      .money {
        font-weight: $font-weight-semi-bold;
        font-size: 32px;
        border-bottom: 4px solid $secondary_color;
        line-height: 1.2;
        max-width: 10rem;
      }
      @include mobile {
        .money {
          font-size: 18px;
        }
      }
      .money-kids {
        font-weight: $font-weight-semi-bold;
        font-size: 32px;
        border-bottom: 4px solid $secondary_color_kids;
        line-height: 1.2;
        max-width: 10rem;
      }
      &.strike-through {
        .money {
          border-bottom: none;
          text-decoration: line-through;
          text-decoration-color: $secondary_color;
        }
        .money-kids {
          border-bottom: none;
          text-decoration: line-through;
          text-decoration-color: $secondary_color_kids;
        }
      }
    }
    //.discount-price {
    //  @include mobile {
    //    margin-top: 1rem;
    //  }
    //}
  }

  .kids-color {
    color: $primary_color_kids !important;
  }

  .kids-border {
    border: 1px solid $primary_color_kids;
  }

  .block-icon-mobile {
    section.blk-icon {
      background: none;
      color: $primary_color;
      margin: 0;
      padding: 2.5em 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      > div {
        padding: 0;
      }
      ul {
        justify-content: flex-start;
        align-items: center;
        li {
          @include mobile {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          @include tablet {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          border: 1px solid $primary_color;
          height: 100%;
          margin-right: 0.5em;
          margin-left: 0.5em;
          padding: 0px 12px;
          height: 36px;
          @include mobile {
            border-radius: 20px;
          }
        }
        picture {
          display: flex;
          align-items: center;
        }
        img {
          width: auto;
          min-width: unset;
          height: 100%;
          margin: 0 8px 0 0;
          max-height: 24px;
        }
        span {
          width: unset;
        }
      }
    }
  }

  .block-icon-mobile-kids {
    section.blk-icon {
      background: none;
      color: $primary_color_kids;
      margin: 0;
      padding: 2.5em 0 0;
      padding: 2.5em 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      > div {
        padding: 0;
      }
      ul {
        justify-content: flex-start;
        align-items: center;
        li {
          @include mobile {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          @include tablet {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          border: 1px solid $primary_color_kids;
          height: 100%;
          margin-right: 0.5em;
          margin-left: 0.5em;
          padding: 0px 12px;
          height: 36px;
        }
        picture {
          display: flex;
          align-items: center;
        }
        img {
          width: auto;
          min-width: unset;
          height: 100%;
          margin: 0 8px 0 0;
          max-height: 24px;
        }
        span {
          width: unset;
        }
      }
    }
  }
}

.small-img-dot {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  LI {
    flex: 0 0 15%; /* explanation below */
    margin: 5px;
    overflow: hidden;
  }
  & {
    scrollbar-width: 4px;
  }

  /* Works on Chrome/Edge/Safari */
  &::-webkit-scrollbar {
    width: auto;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f6f7;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $black !important;
    border-radius: 0px !important;
  }
  LI.section-square {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.2s;
    &:hover {
      cursor: pointer;
      box-shadow: 0px 4px 7px 1px #e8e7e7;
    }
    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
    &.filter-brightness {
      img {
        filter: brightness(0.9);
      }
    }
    img {
      width: 100%;
      height: auto;
      position: absolute;
      left: 0;
      top: 50%;
      object-fit: contain;
      height: 100%;
      transform: translateY(-50%);
    }

    .play-icon {
      z-index: 1;
      color: $primary_color;
      background: #ffffffde;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
