@import '../../styles/common';

.block-login {
  margin-top: 3vw;
  margin-bottom: 3vw;
  & > .loginModal {
    & > .blk-modal {
      display: block !important;
      position: relative !important;
      z-index: unset !important;

      &.fade-out,
      &.fade-in {
        animation: none !important;
      }

      &.fade-in {
        display: none !important;
      }

      & > .mask {
        display: none !important;
      }

      & > .panel {
        position: relative !important;
        top: 0;
        left: 0;
        transform: unset !important;
        z-index: unset !important;

        & > .panel-header {
          display: none !important;
        }
      }
    }
  }
}
