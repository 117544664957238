@import '../../styles/common';
.component-SiteUnderMaintenance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #275f5e;
  color: whitesmoke;

  &.eid {
    background: url('/assets/images/eid.jpeg');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    color: #dbc081;

    & > .SiteUnderMaintenance-big-title {
      @include mobile_tablet {
        line-height: 12vw;
        font-size: 10vw;
        margin-bottom: 5vw;
      }
      font-weight: 900;
      font-size: 4vw;
      margin-bottom: 1vw;
      margin-top: 5vw;
    }

    & > .SiteUnderMaintenance-title {
      @include mobile_tablet {
        line-height: 8vw;
        font-size: 6vw;
      }
      font-size: 3vw;
      font-weight: 600;
      color: #dbc081;
      text-transform: uppercase;
    }

    & > .SiteUnderMaintenance-subtitle {
      @include mobile_tablet {
        line-height: 8vw;
        font-size: 6vw;
      }
      font-size: 2.5vw;
      font-weight: 300;
      color: #dbc081;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      sup {
        font-size: 1.5vw;
      }
    }

    & > .SiteUnderMaintenance-description {
      @include mobile_tablet {
        font-size: 2.6vw;
        margin-top: 5vw;
      }
    }
  }

  & > .SiteUnderMaintenance-big-title {
    @include mobile_tablet {
      line-height: 8vw;
      font-size: 6vw;
    }
    font-size: 3.5vw;
    font-weight: bold;
    color: #dbc081;
    text-transform: uppercase;
  }

  & > .SiteUnderMaintenance-title {
    @include mobile_tablet {
      font-size: 4vw;
    }
    font-size: 2.5vw;
    font-weight: normal;
    text-transform: uppercase;
  }

  & > .SiteUnderMaintenance-image {
    @include mobile_tablet {
      width: 100vw;
      height: 50vw;
    }
    width: 50vw;
    height: 20vw;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  & > .SiteUnderMaintenance-description {
    @include mobile_tablet {
      font-size: 2.2vw;
      line-height: 3vw;
      padding: 0 5vw;
    }
    padding: 0 15vw;
    font-size: 1.3vw;
    font-weight: normal;
    text-align: center;
    a {
      font-weight: bold;
    }
    p {
      margin-bottom: 2vw;
    }
  }
}
