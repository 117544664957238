// stylelint-disable declaration-no-important'
//
// Text
//

// Alignment
.u-text-justify {
  text-align: justify !important;
}

.u-text-wrap {
  white-space: normal !important;
}

.u-text-nowrap {
  white-space: nowrap !important;
}

.u-text-center {
  text-align: center !important;
}

.u-text-right {
  text-align: right !important;
}

.u-text-left {
  text-align: left !important;
}

// Transformation
.u-text-lowercase {
  text-transform: lowercase !important;
}

.u-text-uppercase {
  text-transform: uppercase !important;
}

.u-text-capitalize {
  text-transform: capitalize !important;
}

// Font Weight
.u-font-weight-light {
  font-weight: $font-weight-light !important;
}

.u-font-weight-normal {
  font-weight: $font-weight-normal !important;
}

.u-font-weight-bold {
  font-weight: $font-weight-bold !important;
}

// Font italics
.u-font-italic {
  font-style: italic !important;
}

.u-text-decoration-none {
  text-decoration: none !important;
}

.u-text-break {
  word-wrap: break-word !important;
}

//
.u-text-left {
  text-align: left !important;
}

.u-text-right {
  text-align: right !important;
}

.u-text-center {
  text-align: center !important;
}

// Reset
.u-text-reset {
  color: inherit !important;
}

// Border Top
.u-bor-top {
  border-top: 1px solid $black;
}
.u-text {
  font-size: get-vw(14);
}
// truncate text one line
.u-truncate-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// Line Clamp
.u-line-clamp-none {
  -webkit-line-clamp: unset;
}
.u-line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.u-line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.u-line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.u-line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.u-line-clamp-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.u-text-pre-line {
  white-space: pre-line;
}

.u-text-break-all {
  word-break: break-all;
}
.u-text-break-word {
  word-break: break-word;
}
