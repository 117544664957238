@import '../../styles/common';

SECTION.slide-styles {
  transition: transform 0.2s ease-out;
  position: relative;

  div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    @include mobile_tablet {
      flex-direction: column;
    }
  }
  PICTURE {
    @include mobile {
      display: flex;
    }
    width: 100%;
    height: 100%;
    top: 0;
    //position: absolute;
  }
  img {
    // width: 100% !important;
    // height: auto !important;
    max-width: 100vw;
    object-fit: cover;
    @include mobile {
      width: 100vw !important;
      height: auto !important;
    }
  }
}

UL.slider-styles {
  @include mobile_tablet {
    width: 100vw;
  }
  all: initial;
  width: 100%;
  height: auto;
  // max-height: 30vh;
  // max-height: 100vh;
  // height: 100%;
  display: flex;
  will-change: transform, scale;
  cursor: pointer;
  .slide-outer {
    display: flex;
    align-items: center;
    position: relative;
    .nav-link-full-img {
      @include position($coords: 0, $zindex: 99999);
      width: 100%;
      height: 100%;
      display: block;
      margin: auto;
    }
    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 100000;
      width: 30px;
      height: 30px;
      background: white;
      border-radius: 50%;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      img {
        width: 13px !important;
        height: 13px !important;
      }
    }
  }
}

DIV.slider-wrapper {
  overflow: hidden;
  // width: 100%;
  // height: 100%;
  // max-height: 100vh;
}

DIV.block-slider {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .group-dot {
    position: absolute;
    bottom: 20px;
    left: 50%;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    transition: all 300ms ease-in-out;
    transform: translateX(-50%);
    width: 100%;
    @include mobile {
      bottom: 0;
    }
    .btn-dot {
      @include mobile {
        width: 20px;
      }
      position: relative;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      width: 30px;
      height: 3px;
      margin-right: 3px;
      margin-left: 3px;
      text-indent: -999px;
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.2);
    }
    .btn-dot-active {
      background-color: whitesmoke;
    }
  }
}

.component-slider-next,
.component-slider-prev {
  @include mobile {
    transform: translateY(-50%) scale(0.7);
  }
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  right: 2%;
  cursor: pointer;
  background: whitesmoke;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
  padding-left: 4px;
  transform: translateY(-50%);
  &:hover {
    opacity: 1;
  }
  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
}
.component-slider-prev {
  left: 2%;
  padding-left: 0px;
  padding-right: 4px;
  img {
    transform: rotate(180deg);
  }
}
