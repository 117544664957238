@import '../../styles/common';
SECTION.cookie-modal {
  z-index: 10000;
  width: 100vw;
  height: auto;
  bottom: 0px;
  height: auto;
  padding: 1em;
  //right: 5%;
  //background-color: $primary_color_light;
  //color: $primary_color;
  background-color: rgba(255, 255, 255, 0.9);
  color: black;
  //box-shadow: 5px 3px 10px 1px $grey_color;
  @include mobile {
    bottom: 50px;
  }
  &.hidden {
    z-index: -1;
  }
  > .cookie-modal-content {
    max-height: 100%;
    display: flex;
    flex-direction: 'row';
    justify-content: center;
    overflow: hidden;
    align-items: center;
    gap: 1em;
    @include mobile {
      justify-content: space-evenly;
      align-items: space-around;
      //padding: 1em;
    }
  }
}

.open {
  display: block;
  position: fixed;
}
.closed {
  display: none;
}
.cookie-policy-button {
  background-color: $primary_color_light;
  height: 100%;
  border: 1px solid $primary_color;
  color: $primary_color;
  padding: 1em;
  @include mobile {
    width: 100%;
    height: auto;
    margin-top: 1em;
  }
  &:hover {
    color: $primary_color_light;
    border: 1px solid $primary_color_light;
    background-color: $primary_color;
  }
}

#cookie-header {
  text-decoration: underline;
  @include mobile {
    margin-bottom: 1rem;
  }
}

//#cookie-content {
//  margin-left: 1 rem;
//}

#cookie-policy-link {
  text-decoration: underline;
  &:hover {
    //background-color: $primary_color;
    //color: $primary_color_light;
    background-color: rgba(255, 255, 255, 0.7);
    color: black;
    text-decoration: none;
  }
}

#cookie-text {
  align-self: baseline;
}
