SECTION.tab-MyAccount {
  position: relative;
  z-index: 8;
  .text-guest {
    color: $primary_color;
  }
  > h1 {
    @include font-size(32px);
    margin-bottom: 40px;
    font-weight: $font-weight-semi-bold;
    @include mobile {
      margin-bottom: 24px;
      margin-top: 24px;
      text-align: center;
      @include font-size(24px);
      text-transform: uppercase;
    }
    @include tablet {
      margin-bottom: 24px;
      margin-top: 24px;
      @include font-size(24px);
      text-transform: uppercase;
    }
  }
  h3 {
    @include mobile {
      margin-bottom: 8px;
    }
  }
  form {
    h3 {
      font-weight: $font-weight-semi-bold;
    }
    > div {
      padding-bottom: 24px;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 48px;
    }
  }
  SECTION.wrapper-content-map {
    margin-bottom: 24px;
    .submit-box {
      gap: 6px;
      BUTTON {
        padding: 10px 12px;
        @include font-size(14px);
        flex-grow: 1;
        width: 100%;
      }
    }
  }
  .checkbox-button {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  BUTTON.btn-change-password {
    background: $primary_color;
    color: white;
    padding: 10px 0px;
    font-size: 16px;
    letter-spacing: 0.1em;
    width: 32%;
    margin-left: auto;
    margin-right: 12px;
    &:hover {
      background: #2a676bdb;
    }
  }
  SECTION.adaptive-input {
    background: red;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 16px;
    .view-password {
      position: absolute;
      top: 0%;
      right: 15px;
      width: 20px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .validate-field {
      background: $primary_color;
      display: flex;
      align-items: baseline;
      @include font-size(14px);
      padding: 8px;
      color: $white;
      position: absolute;
      right: -24px;
      transform: translateX(100%);
      min-width: 280px;
      border-radius: 2px;
      img {
        width: 14px;
        display: inherit;
        transform: translateY(2px);
        margin: 0 4px;
      }
    }
    .validate-field::before {
      content: '';
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 7px solid $primary_color;
      border-bottom: 5px solid transparent;
      position: absolute;
      left: 0px;
      transform: translateX(-100%);
      top: 8px;
    }
  }
  .form-group {
    .phone-input {
      .adaptive-input {
        input {
          padding-left: 38px;
        }
        label {
          transform: translate(0, 6px) scale(0.8);
          &:after {
            content: '+88';
            position: absolute;
            top: 21px;
            left: 9px;
            color: $black;
            font-size: convert-rem(20);
            font-weight: $font-weight-semi-bold;
          }
        }
      }
    }
  }
  .off-animation-text {
    .adaptive-input label {
      transform: translate(0, 8px) scale(0.8);
    }
  }
  SECTION.adaptive-input input {
    width: 100%;
    height: 50px;
    padding: 14px 16px 0 10px;
    outline: 0;
    border: 1px solid #ddd;
    background: #fff;
    font-size: 16px;
    // &:focus {
    //   border: 1px solid red;
    // }
    &:read-only {
      background-color: #f0f0f0;
    }
  }

  SECTION.adaptive-input select {
    width: 100%;
    height: 50px;
    padding: 14px 16px 14px 16px;
    outline: 0;
    border: 1px solid #ddd;
    background: #fff;
    font-size: 16px;
  }

  SECTION.adaptive-input textarea {
    width: 100%;
    /* height: 50px; */
    padding: 24px 16px 0 10px;
    outline: 0;
    border: 1px solid #ddd;
    background: #fff;
    font-size: 16px;
  }

  SECTION.adaptive-input label {
    @include font-size(14px);
    padding: 0 12px;
    color: #999;
    pointer-events: none;

    position: absolute;
    transform: translate(0, 18px) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;
  }

  SECTION.adaptive-input:focus-within label {
    transform: translate(0, 8px) scale(0.8);
  }
  SECTION.adaptive-input .Active {
    transform: translate(0, 8px) scale(0.8);
  }

  DIV.primary-select {
    min-height: 42px;
    width: 40%;
    padding-left: 8px;
    font-size: 14px;
    .react-dropdown-select-item {
      padding: 8px 10px;
    }
  }
  // custom checkbox button

  input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }

  .address-info,
  .btn-add-address {
    display: block;
    width: 100%;
    text-align: left;
    padding: 12px 16px;
    @include font-size(14px);
    border: 1px solid $primary_color;
    color: $primary_color;
    font-weight: $font-weight-semi-bold;
    margin-bottom: 16px;
    transition: 0.1s;
  }
  .address-info:hover {
    opacity: 0.8;
  }
  .btn-add-address {
    &:hover {
      background: $primary_color;
      color: $white;
    }
    letter-spacing: 0.1em;
  }
}
