@import '../../styles/common';
SECTION.loginModal {
  .terms-cond {
    text-align: justify;
    margin-top: 1rem;
    @include font-size(12px);
    a {
      color: $primary-color;
      font-weight: $font-weight-semi-bold;
    }
  }
  .newsletter-text {
    margin-top: 1rem;
    text-align: justify;
  }
  .newsletter {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;

    a {
      color: $primary_color;
      font-weight: 600;
    }

    .checkbox-button {
      cursor: pointer;
      display: block;
      @include font-size(14px);
      position: relative;

      input:checked + .checkbox-button__control:after {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: 3px;
        width: 10px;
        height: 10px;
        background-color: $primary_color;
        border-radius: 1px;
      }
      input:checked + .checkbox-button__control {
        border-color: $primary_color;
      }

      input {
        font-size: 1rem;
        line-height: 1.5;
        // padding: 11px 23px;
        border: 1px solid #dddddd;
        border-radius: 0;
        outline: 0;
        background-color: $primary_color;
      }
      &__label {
        font-weight: $font-weight-semi-bold;
        @include font-size(16px);
        a {
          display: inline;
        }
      }
      &__input {
        opacity: 0;
        position: absolute;
      }
      &__control {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 4px;
        vertical-align: middle;
        background-color: inherit;
        color: $white;
        border-radius: 1px;
        border: 2px solid #dddddd;
      }
      &__control {
        transform: scale(0.75);
      }
    }
  }
  .register-success {
    text-align: center;
    & > h2 {
      color: $primary-color;
    }
    p {
      margin-top: 1rem;
      color: $grey;
    }
  }
  .primary-select {
    height: 45px;
  }
  .title-form {
    text-align: center;
    margin-bottom: 24px;
    @include font-size(28px);
    font-weight: $font-weight-semi-bold;
  }
  .d-flex-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .form-group {
    margin-bottom: 4px;
    > label {
      margin-bottom: 8px;
      display: block;
      & > .required {
        color: red;
      }
    }
    flex-basis: 49%;
    @include mobile {
      flex-basis: 100%;
    }
    &.phone-input {
      .adaptive-input {
        input {
          padding-left: 38px;
        }
        label {
          transform: translate(0, 6px) scale(0.8);
          &:after {
            content: '+88';
            position: absolute;
            top: 17px;
            left: 9px;
            color: $black;
            font-size: convert-rem(20);
            font-weight: $font-weight-semi-bold;
          }
        }
      }
    }
  }
  .social-media-box {
    .text-box {
      .line {
        width: 100px;
        height: 2px;
        margin: 0 auto;
        background-color: $secondary_color;
        margin-bottom: 20px;
      }
      .or-connect-with {
        text-align: center;
      }
      margin-bottom: 10px;
    }
    .social-media {
      display: inline-block;
      width: auto;
      .btn-google {
        vertical-align: middle;
        IMG {
          width: 66px;
          height: 66px;
        }
      }
    }
  }
  .wrapper-form {
    padding: 8px 32px 32px;
    @include mobile {
      padding: 8px 8px 32px;
    }
  }
  .err-login {
    color: $danger_color;
    font-size: 14px;
    height: 1rem;
    line-height: 0.1rem;
  }
  .submit-box {
    display: flex;
    align-items: flex-start;
    margin: 14px 0 8px;
    justify-content: space-between;
    button {
      background: $primary_color;
      color: $white;
      padding: 10px 60px;
      width: 100%;
      border-radius: 5px;
      @include font-size(16px);
      letter-spacing: 0.1em;
      @include mobile {
        padding: 10px;
        @include font-size(14px);
        width: 100%;
      }
    }
    button.btn-forgot-password {
      background: none;
      padding: 10px 0;
      color: $primary_color;
      text-decoration: underline;
      @include mobile {
        padding: 10px;
        @include font-size(14px);
      }
    }
    a {
      font-size: 0.875rem;
      text-decoration: underline;
      color: $primary_color;
    }
    .text-with-loader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  SECTION.adaptive-input {
    display: flex;
    flex-direction: column;
    position: relative;
    .validate-field {
      background: $primary_color;
      display: flex;
      align-items: baseline;
      @include font-size(12px);
      padding: 7px;
      color: $white;
      position: unset;
      right: 0;
      transform: translateX(0);
      margin-top: 4px;
      border-radius: 2px;
      img {
        width: 11px;
        display: inherit;
        transform: translateY(2px);
        margin: 0 4px;
      }
    }
    .validate-field::before {
      display: none;
      content: '';
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 7px solid $primary_color;
      border-bottom: 5px solid transparent;
      position: absolute;
      left: 0px;
      transform: translateX(-100%);
      top: 8px;
    }
    input {
      width: 100%;
      height: 45px;
      padding: 14px 16px 0 10px;
      outline: 0;
      border: 1px solid #ddd;
      background: $white;
      color: $black;
      font-size: 16px;
      border-radius: 5px;
    }
    textarea {
      width: 100%;
      padding: 24px 16px 0 10px;
      outline: 0;
      border: 1px solid $white;
      background: $white;
      color: $black;
      font-size: 16px;
    }
    label {
      @include font-size(14px);
      padding: 0 12px;
      color: #999;
      pointer-events: none;

      position: absolute;
      transform: translate(0, 15px) scale(1);
      transform-origin: top left;
      transition: all 0.2s ease-out;
    }

    &:focus-within label {
      transform: translate(0, 6px) scale(0.8);
    }

    .Active {
      transform: translate(0, 6px) scale(0.8);
    }

    .view-password {
      position: absolute;
      top: 0%;
      right: 15px;
      width: 20px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .sms-verify {
    @include mobile {
      gap: 5vw;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    gap: 1vw;

    & > .adaptive-input {
      width: 100%;
    }

    & > .verify-buttons {
      display: flex;
      width: 100%;
      gap: 0.5vw;

      & > .btn {
        @include mobile {
          height: 20vw;
          font-size: 4vw;
        }
        flex: 1;
        width: 100%;
        height: 4vw;
        max-height: 45px;
        background: $primary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 1vw;
        &.disable {
          background-color: #cecece;
        }
      }
    }
    & > .verify-description {
      width: 100%;
      font-size: 0.8em;
      padding: 0 0.2em;
    }
  }
}
