@import '../../styles/common';

.whatsapp {
  @include mobile {
    position: relative !important;
    bottom: unset !important;
    right: unset !important;
    width: 100% !important;
    height: 100% !important;
    box-shadow: none !important;
    top: 1px !important;

    .whatsapp-button {
      box-shadow: none !important;
      & > .styles-module_whatsappButton__IWx9V {
        box-shadow: none !important;
        right: unset;
        bottom: unset;
        &::after {
          content: none;
        }
        width: 40px !important;
        height: 40px !important;

        & > svg {
          width: 40px !important;
          height: 40px !important;
        }
      }
    }
  }
  bottom: 94px;
  right: 24px;
  z-index: 100;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .whatsapp-button {
    & > .styles-module_whatsappButton__IWx9V {
      right: unset;
      bottom: unset;
      width: 40px;
      height: 40px;
      &::after {
        content: none;
      }
    }
  }
}
