LABEL.radio-voucher {
  margin: 0.5rem;

  .radio-label {
    margin: -0.4rem 0;
    > div {
      // margin-top: 0.3rem;
      font-size: 0.75rem;
    }
  }
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: '';
        background: none;
        border-radius: 100%;
        border: 1px solid #c1c0c0;
        display: inline-block;
        width: 14px;
        height: 14px;
        position: relative;
        top: 1px;
        margin-right: 8px;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 150ms ease;
        margin-top: 0.1rem;
        margin-right: 1.5rem;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $primary_color;
          box-shadow: inset 0 0 0 3px $white;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: #c1c0c0;
        }
      }
    }
    &:disabled {
      + .radio-label {
        color: $border-grey;
        &:before {
          background: none;
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}
