@import '../../styles/common';

section.wrapper-content-map {
  input {
    height: 40px;
    border: 1px solid $grey;
    width: 100%;
    padding: 0 14px;
  }
  .submit-box {
    display: flex;
    align-items: flex-start;
    margin: 8px 0;
    justify-content: space-between;
    button {
      background: $primary_color;
      color: white;
      padding: 10px 60px;
      font-size: 16px;
      letter-spacing: 0.1em;
    }
    a {
      @include font-size(14px);
      text-decoration: underline;
      color: $primary_color;
    }
  }
  .address-info.active {
    background: $primary_color;
    color: $white;
  }
}

.adaptive-input {
  position: relative;
  & > label.select {
    top: 10%;
    z-index: 9;
    &.Active {
      display: none;
    }
  }
}

.select__menu {
  z-index: 10 !important;
  background: white !important;
}
