@import '../../styles/common';
SECTION.blk-notfound {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 40%;
  }
  a {
    color: $white;
    padding: 1em 5em;
    // background: #2a676b;
    margin: 1em auto;
    color: $black;
    @include font-size(16px);
    text-transform: uppercase;
    letter-spacing: 0.1em;
    transition: 0.3s ease-in-out;
    text-decoration: underline;
    &:hover {
      color: $primary-color;
    }
  }
}
