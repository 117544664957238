.render-page {
  .paragraph {
    padding: '32px 10px';
    line-height: 1.5rem;
    ul {
      list-style: initial;
      li {
        margin-left: 1.2rem;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      P {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
      IMG {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        @include tablet {
          width: 100% !important;
        }
        @include mobile {
          width: 100% !important;
        }
        height: auto !important;
        object-fit: contain;
        max-width: 100%;
      }

      .full-width {
        align-self: center;
      }
      .center {
        align-self: center;
      }
      .left {
        align-self: flex-start;
      }
      .right {
        align-self: flex-end;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: normal;
      }
    }
  }

  .list-product {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
}
