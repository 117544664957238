@import '../../styles/common';
SECTION.tab-Reviews {
  h1 {
    @include font-size(32px);
    margin-bottom: 40px;
    font-weight: $font-weight-semi-bold;
    @include mobile {
      margin-bottom: 24px;
      margin-top: 24px;
      text-align: center;
      @include font-size(24px);
      text-transform: uppercase;
    }
    @include tablet {
      margin-bottom: 24px;
      margin-top: 24px;
      @include font-size(24px);
      text-transform: uppercase;
    }
  }
  .head {
    background: $primary_color_light;
    text-transform: uppercase;
    @include font-size(14px);
    margin: 0;
    padding: 12px 32px 12px 18px;
  }
  .row-item {
    margin: 0;
    position: relative;
    border-bottom: 1px solid rgb(204, 204, 204);
    padding: 32px 0;
    cursor: pointer;
    @include mobile {
      padding: 24px 0;
      margin: 0 14px;
    }
    &:hover {
      .expand-ic,
      .close-ic {
        opacity: 1 !important;
      }
    }
  }
  .dragging {
    .expand-ic,
    .close-ic {
      opacity: 1 !important;
    }
  }
  .wrapper-img {
    text-align: center;
    IMG {
      object-fit: contain;
      width: 100%;
      height: auto;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    align-self: center;
    @include mobile {
      font-size: 14px;
      h4 {
        margin-bottom: 4px;
      }
    }
  }
  .price-info,
  .price-info h3 {
    display: flex;
    align-self: center;
  }
  .expand-ic {
    width: 24px;
    margin: 0 auto;
    opacity: 0.5;
    transition: 0.3s all;
  }
  .close-ic {
    width: 37px;
    position: absolute;
    right: 4px;
    opacity: 0.5;
    transition: 0.3s all;
    top: 8px;
  }
  .sale-inf {
    width: 48px;
    height: 48px;
    background: $secondary_color;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 50px;
    @include font-size(16px);
    font-weight: $font-weight-semi-bold;
    margin-left: 5em;
    &.bonus {
      flex-direction: column;
      > span > span {
        @include font-size(16px);
        font-weight: $font-weight-semi-bold;
      }
    }
    @include mobile {
      width: 40px;
      height: 40px;
      @include font-size(14px);
    }
  }
  .review-wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    .review-section {
      flex-grow: 1;
      @include mobile {
        margin-top: 12px;
      }
    }
    .checkbox-button {
      margin-bottom: 16px;
    }
    textarea {
      width: 100%;
      border-color: #cccccc;
      margin-bottom: 4px;
      padding: 8px;
      &:focus {
        outline: none;
      }
    }
    button {
      padding: 0.8em 1em;
      width: 50%;
      border: 1px solid #215154;
      color: $primary_color;
      text-transform: uppercase;
      height: fit-content;
      transition: 0.1s ease-in;
      &:hover {
        background: $primary_color;
        color: $white;
      }
    }
  }

  .no-review {
    line-height: 5rem;
    text-align: center;
    font-size: 24px;
  }
}
