@import '../../styles/common';

SECTION.product-note-modal {
  .wrapper-img {
    overflow: hidden;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }
  .content-note {
    padding: 14% 8% 8%;
    @include mobile {
      max-height: 65vh;
      overflow-y: auto;
    }
    .title {
      color: $primary_color;
      font-weight: $font-weight-normal;
      @include font-size(32px);
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-end;
      text-transform: uppercase;
      white-space: nowrap;
      &::after {
        content: '';
        height: 4px;
        background: $primary_color;
        margin: 0 auto 8px 1em;
        flex-grow: 1;
      }
    }
    p {
      padding: 24px 0;
      color: $primary_color;
    }
  }

  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 24px;
    .col-left {
      display: flex;
      flex-direction: column;
      width: 70%;
    }
    .scent-strenght {
      display: flex;
      align-items: baseline;
    }

    .wrap-strength {
      width: 34%;
      position: relative;
      overflow: hidden;
      .fill-percent {
        width: 0%;
        background: $primary_color;
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 1px;
        transition: 0.3s;
      }

      // .level-strength {
      //   // -webkit-animation-name: level5;
      //   // animation-name: level5;
      // }
      .level-4 {
        width: 82%;
      }
      .level-3 {
        width: 57%;
      }
      .level-2 {
        width: 39%;
      }
      .level-1 {
        width: 20%;
      }

      // @-webkit-keyframes level5 {
      //   from {
      //     width: 0%;
      //   }

      //   to {
      //     width: 99%;
      //   }
      // }

      @keyframes level5 {
        from {
          width: 0%;
        }
        to {
          width: 99%;
        }
      }

      img {
        width: 100%;
        position: relative;
        object-fit: contain;
        z-index: 1;
        display: block;
      }
    }
    h1 {
      @include font-size(28px);
      margin-left: 14px;
      color: $primary_color;
      font-weight: $font-weight-light;
    }
    h2 {
      @include font-size(18px);
      font-weight: 400;
      color: #2a676b;
    }
    .col-right {
      width: 100%;
      text-align: center;
      A {
        background: $primary_color;
        color: white;
        text-align: center;
        padding: 16px 20px;
        font-size: 16px;
        font-weight: 400;
        margin-top: -35px;
        position: relative;
        display: block;
      }
      img {
        object-fit: contain;
      }
    }
  }
}
