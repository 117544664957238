@import '../../styles/common';

SECTION.blk-collect-email {
  padding: 1em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 16em;
  position: relative;
  .content {
    position: relative;
    z-index: 99999;
  }
  PICTURE {
    @include position(0, $zindex: 0);

    IMG {
      display: block;
      width: 100%;
      height: 100%;
      object: {
        fit: cover;
        position: center;
      }
    }
  }

  .content {
    IMG {
      min-width: 6em;
      display: inherit;
      margin: 0 auto 2.5em;
    }
    padding: 1em 0 2em;
    text-align: center;
    & > * {
      margin-bottom: 1em;
    }
    h3 {
      @include font-size(20px);
      color: $white;
      font-weight: 300;
      span {
        color: #ffa80f;
        @include font-size(27px);
        font-weight: 300;
      }
    }
    input,
    select {
      display: block;
      margin: 0 auto;
      border: none;
      padding: 1em;
      width: 85%;
      margin-bottom: 14px;
      @include font-size(14px);
    }
    button.btn-submit {
      padding: 1em;
      background: $primary_color;
      display: block;
      width: 85%;
      margin: 1em auto;
      color: $white;
      @include font-size(14px);
      text-transform: uppercase;
      letter-spacing: 0.2em;
    }
  }

  p {
    color: $white;
    @include font-size(12px);
  }
  p.footer {
    position: relative;
    z-index: 99999;
    a {
      text-decoration: underline;
    }
  }
}
