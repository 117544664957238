@import '../../styles/common';

.component-NavigationMobileV2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.2s all;
  background-color: $white;
  color: $primary_color;
  z-index: 99;
  display: flex;
  flex-direction: column;

  & > .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--main-color);
    width: 100%;
    height: 50px;
    padding: 5px 16px;
    gap: 30px;

    & > .logo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      a {
        display: block;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: left;
        }
      }
    }
    & > .search {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: white;
      height: 25px;
      border-radius: 5px;

      & > div {
        width: calc(100vw - 160px);
        height: 100%;

        .search-box {
          position: relative;
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: space-between;
          align-items: center;
          & > input {
            border-radius: 5px;
            border: none;
            width: 85%;
            height: 100%;
            padding-left: 7px;
            padding-right: 5px;
          }

          & > button {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
              transform: scale(0.75);
            }
          }
        }
      }
    }
    & > .cart {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .icon-bag {
        margin-right: 5px;
        i {
          color: $secondary_color;
        }
      }
    }
  }

  & > .bot {
    flex: 1;
    ul {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //padding-left: 16px;
      //padding-right: 16px;
      //gap: 16px;
      li {
        width: 100%;
        height: 100%;
        flex: 1;
        color: var(--main-color);
        &.active {
          background: var(--main-color);
          color: white;
        }
        a {
          width: 100%;
          height: 100%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
        }
      }
    }
  }
}
