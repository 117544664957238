@import '../../styles/common';

SECTION.blk-carousel {
  @include mobile {
    margin-top: 0px;
  }

  DIV.carousel-block {
    @include mobile {
      min-height: unset;
      height: unset;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: relative;
    // width: 100vw;
    // min-height: 30rem;

    @media only screen and (max-width: 599px) {
      min-height: 12em;

      &.has-text {
        min-height: 130vw !important;
        height: auto !important;
        .slide-inner {
          height: auto !important;
        }
        .slide-styles {
          justify-content: flex-start;
          height: auto !important;
        }
        picture {
          position: relative;
          height: auto;
          img {
            width: 100% !important;
            //height: 70vw !important;
            object-fit: contain;
          }
        }

        .content-slide {
          position: relative;
          bottom: unset;
          top: unset;
          display: flex;
          justify-content: center !important;
          align-items: center !important;
        }
      }
    }

    @media only screen and (min-width: 600px) and (max-width: 1025px) {
      min-height: 20em !important;

      &.has-text {
        min-height: 100vw !important;
        height: auto !important;
        .slide-inner {
          height: auto !important;
        }
        .slide-styles {
          justify-content: flex-start;
          height: auto !important;
        }
        picture {
          position: relative;
          height: auto;
          img {
            width: 100% !important;
            height: 70vw !important;
            object-fit: contain;
          }
        }

        .content-slide {
          position: relative !important;
          bottom: unset;
          top: unset;
          display: flex;
          justify-content: center !important;
          align-items: center !important;
        }
      }
    }

    @media only screen and (min-width: 1025px) {
      &.has-text {
        // min-height: 60vw !important;
        height: auto !important;
        .slide-inner {
          height: auto !important;
        }
        .slide-styles {
          justify-content: flex-start;
          height: auto !important;
        }
        picture {
          position: relative;
          height: auto;
          img {
            width: 100% !important;
            // height: 50vw !important;
            object-fit: contain;
          }
        }

        .content-slide {
          position: absolute !important;
          bottom: 0 !important;
          top: unset !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
        }
      }
    }
  }
  DIV.content-slide {
    position: absolute;
    left: 0;
    background: var(--main-color);
    color: var(--secondary-color);
    min-height: 30%;
    bottom: 0;
    width: 35%;
    padding: 2em;
    text-align: center;
    // max-height: 40%;
    z-index: 55555;
    overflow: hidden;
    @include flexbox(flex, column, nowrap, center, center, stretch);
    @include mobile_tablet {
      width: 100%;
      // position: relative;
      overflow: hidden;
    }
    @include mobile {
      min-height: 186px;
    }
    > p {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      text-transform: none;
    }
    &::after {
      content: url('../../images/bg-slider-content.png');
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
    H2 {
      margin-bottom: 1em;
      padding: 0 1em;
      font-size: convert-rem(28);
      font-weight: $font-weight-light;
      letter-spacing: 0.1em;
      @include mobile {
        @include font-size(18px);
      }
    }
    P {
      font-size: 19px;
      text-transform: uppercase;
      font-weight: $font-weight-normal;
      letter-spacing: 0.1em;
      @include tablet_under {
        font-size: 12px;
      }
    }
  }

  DIV.carousel-block {
    // position: relative;
    .component-BlockCarousel-button {
      cursor: pointer;
      position: absolute;

      width: auto;
      height: auto;
      padding: 10px 25px;
      z-index: 100000;
      border: 3px black solid;
      border-radius: 5px;
      font-weight: bold;
      font-size: 18px;
      white-space: nowrap;
      background-color: rgb(255, 255, 255, 0.2);
      @include mobile {
        font-size: 12px;
        border-radius: 16px;
      }

      &.component-BlockCarousel-button-position-left_top {
        left: 10%;
        top: 10%;
      }
      &.component-BlockCarousel-button-position-left_center {
        left: 10%;
        top: 50%;
        transform: translate(0%, -50%);
      }
      &.component-BlockCarousel-button-position-left_bottom {
        left: 10%;
        bottom: 10%;
        @include mobile {
          bottom: 5%;
        }
      }
      &.component-BlockCarousel-button-position-right_top {
        right: 5%;
        top: 10%;
      }
      &.component-BlockCarousel-button-position-right_center {
        right: 5%;
        top: 50%;
        transform: translate(0%, -50%);
      }
      &.component-BlockCarousel-button-position-right_bottom {
        right: 5%;
        bottom: 10%;
        @include mobile {
          bottom: 5%;
        }
      }
      &.component-BlockCarousel-button-position-center_top {
        left: 50%;
        top: 10%;
        transform: translate(-50%, 0%);
      }
      &.component-BlockCarousel-button-position-center_center,
      &.component-BlockCarousel-button-position-,
      &.component-BlockCarousel-button-position-null {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &.component-BlockCarousel-button-position-center_bottom {
        left: 50%;
        bottom: 10%;
        transform: translate(-50%, 0%);
        @include mobile {
          bottom: 5%;
        }
      }
    }
  }
}
