@import '../../styles/common';
.blk-product-slide {
  position: relative;
  &::after {
    content: '';
    //   background-color: red;
    //   min-width: 9em;
    //   /* height: 10px; */
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   bottom: 0;
    //   background: linear-gradient(
    //     90deg,
    //     rgb(255 255 255 / 0%) 0%,
    //     rgb(255 255 255 / 63%) 20%,
    //     #ffffff 100%
    //   );
  }
  // -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgb(0 0 0 / 0%));
  // -webkit-mask-size: 100% 100%;
  // -webkit-mask-repeat: no-repeat;
  // -webkit-mask-position: right top, right bottom;
  .btn-add {
    padding: 0.8em;
    border: 1px solid $primary_color;
    margin-top: 1.5em;
    color: $primary_color;
    font-weight: $font-weight-semi-bold;
    transition: 0.1s ease-in-out;
    margin-top: 1em;
    width: 100%;
    &:hover {
      background: $primary_color;
      color: $white;
    }
  }
  .x-mandatory {
    scroll-snap-type: x mandatory;
    overflow: scroll;
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-wrap: nowrap;
    padding: 2em 0 4em;
    > div {
      scroll-margin: 10px;
      scroll-snap-align: start;
      scroll-snap-stop: normal;
      padding: 0 1em;
      @include font-size(14px);
    }
    p {
      white-space: unset !important;
      text-transform: none;
      @include font-size(15px);
      min-height: 2em;
      overflow: hidden;
    }
    /* The emerging W3C standard
     that is currently Firefox-only */
    & {
      scrollbar-width: 6px;
      // scrollbar-height: 4px;
      scrollbar-width: thin;
      scrollbar-color: $black $grey;
    }

    /* Works on Chrome/Edge/Safari */
    &::-webkit-scrollbar {
      width: auto;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $black;
      border-radius: 0px;
    }
  }

  .wrapper {
    width: 100%;
    text-transform: uppercase;
    color: $black;
    text-align: center;
    height: 100%;
    position: relative;
    .sale-inf {
      margin-top: 0;
      right: 0;
      top: 0;
      position: absolute;
      width: 3rem;
      height: 3rem;
      background: $secondary_color;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      border-radius: 50%;
      font-weight: $font-weight-normal;
      z-index: 1;
      font-size: 0.7rem;
      &.bonus {
        flex-direction: column;
        > span > span {
          font-size: 16px;
          font-weight: $font-weight-semi-bold;
        }
      }
      @include mobile {
        width: 40px;
        height: 40px;
        font-size: convert-rem(14);
      }
    }
    h4 {
      margin-bottom: 1em;
      margin-top: 1em;
    }
    span {
      display: inline-block;
      font-weight: $font-weight-semi-bold;
      @include font-size(16px);
    }
    > div {
      display: flex;
      flex-flow: column;
      flex-direction: column;
      height: 100%;
      :hover {
        cursor: pointer;
      }
    }
  }

  .section-square {
    position: relative;
    object-fit: contain;
    object-position: center;
    vertical-align: middle;
    width: 100%;
    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      object-fit: contain;
    }
    .sale-inf {
      width: 50px;
      height: 50px;
      background: $secondary_color;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      border-radius: 50px;
      @include font-size(16px);
      font-weight: $font-weight-semi-bold;
      position: absolute;
      top: -20%;
      right: 0%;
    }
  }
  .item-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex: 1;
    .perfume-type {
      color: $grey;
    }
    .strike-through {
      border-bottom: none;
      text-decoration: line-through;
      text-decoration-color: $secondary_color;
      text-decoration-thickness: 2px;
    }

    .discount-price {
      color: var(--secondary-color);
    }
  }
  .sale-price {
    background: $secondary_color;
    padding: 0 8px;
  }

  .discount {
    .price {
      background: $secondary_color;
      padding: 0 4px;
      white-space: nowrap;
    }
    .price-discounted {
      margin-top: unset;
      @include font-size(13px);
      color: $danger_color;
      justify-content: flex-end;
    }
  }
}
