.MuiSpeedDial-root {
  & > button.MuiButtonBase-root {
    background-color: transparent;
    border: 1px solid var(--main-color);
    & > span.MuiSpeedDialIcon-root {
        height: 40px;
        width: 40px;
        img {
            filter: brightness(0) saturate(100%) invert(32%) sepia(61%) saturate(419%)
              hue-rotate(135deg) brightness(92%) contrast(87%) !important;
            position: relative;
          }
    }

    & > .css-49h81w-MuiSpeedDialIcon-root {
        img.MuiSpeedDialIcon-icon {
            transform: none;
        }
    }
    
  }
}
