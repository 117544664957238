// file paths
$static: '/static/instore/';
$dashstatic: '/static/dashboard/';
$imgpath: '#{$static}img/';
$dashimgpath: '#{$dashstatic}img/';
$fontpath: '#{$static}fonts/';
$dashfontpath: '#{$dashstatic}fonts/';
$mainsprite: '#{$imgpath}sprite_general.png';
$wagtail-font-path: '#{$static}wagtailadmin/fonts/';

// fonts
$default-font: Roboto;
$default-font-family: unquote('#{$default-font}, sans-serif');
$headline-font: 'Playfair Display';
$headline-font-family: unquote('#{$headline-font}, serif');
$dash-font: Montserrat;
$dash-font-family: unquote('#{$dash-font}, serif');
$dash-headline: 'Abril Fatface';
$dash-headline-family: unquote('#{$dash-headline}, serif');

// sizes
$base-font-size: 16px;
$base-font-mobile-size: 14px;

//font-weight
$font-weight-light: 400;
$font-weight-normal: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$tablet-landscape-size: 1024px;
$small-site-size: 900px;
$tablet-portrait-size: 768px;
$mobile-site-size: 735px; // iphone 6+ has 414*736px
$mobile-small-site-size: 500px; // iphone 6 has 375px
$mobile-smallest-site-size: 375px; // iphone 6 has 375px

$very-small-margin: 5px;
$small-margin: 15px;
$default-margin: 20px;
$medium-margin: 30px;
$large-margin: 40px;
$very-large-margin: 75px;
$huge-margin: 100px;

$very-small-padding: 5px;
$small-padding: 15px;
$default-padding: 20px;
$medium-padding: 30px;
$large-padding: 60px;
$very-large-padding: 75px;
$huge-padding: 100px;

$header-toggle-nav-size: 36px;
$site-offset-distance: 330px;
$tothetop-icon-size: 60px;
$pagination-link-size: 40px;
$icon-default-size: 30px;
$icon-large-size: 60px;

$site-max-size: 1280px;
$site-default-anim-timing: 250ms;
$site-default-long-anim-timing: 500ms;

// INSTORE CONFIG

$site-content-max-size: 960px;
$site-content-mid-size: 768px;

$header-height: 162px;
$header-logo-height: 15px;
$header-logo-width: 95px;
$header-clientlogo-max-height: 58px;
$header-clientlogo-min-height: 50px;

$slider-height: 82px;
$slider-hinttext-width: 125px;
$slider-object-size: 50px;
$slider-object-start-anim-name: sliderAnim;
$slider-x-small-size: 20px;

$site-content-min-height-diff: $header-height + $slider-height;

$form-default-input-height: 32px;
$form-default-max-width: 300px;
$form-default-button-height: 44px;
$form-default-button-width: 200px;
$form-default-anim-offset: 20px;
$form-icon-edit-size: 26px;

$searchresult-avatar-size: 106px;
$searchresult-avatar-mobile-size: 94px;

$navigation-min-width: 320px;
$navigation-max-width: 390px;
$navigation-opacity-factor: 0.4;

$experience-content-max-width: 400px;
$experience-figure-height: 200px;
$experience-button-height: 44px;

$profile-info-max-width: 340px;
$profile-info-min-width: 340px;
$profile-info-image-width: 250px;
$profile-info-title-font-size: 32px;
$profile-info-button-size: 24px;
$profile-map-height: 350px;
$profile-addon-offset-right: 95px;
$profile-addon-mobile-offset-right: 35px;
$profile-date-font-size: 12px;

$profile-purchase-price-width: 95px;
$profile-purchase-button-size: 24px;
$profile-purchase-chat-button-height: $experience-button-height;

$chat-chatlist-width: 300px;
$chat-chatlist-mobile-width: 200px;
$chat-chatlist-mobile-height: 120px;
$chat-chatlist-button-size: 44px;
$chat-chatlist-anim-name: chatVenueAnimation;
$chat-textarea-height: 100px;
$chat-textarea-icon-size: 16px;
$chat-bubble-max-width: 640px;
$chat-bubble-font-size: 12px;

// DASHBOARD CONFIG
$dash-headline-font-size: 40px;
$dash-dashboard-header-height: 40px;
$dash-dashboard-item-min-height: 260px;
$dash-dashboard-margin-size: 20px;
$dash-dashboard-item-default-width: 380px;

$dash-currency-width: 70px;

$dash-header-height: 140px;
$dash-header-mobile-height: 110px;
$dash-header-content-max-height: 70px;
$dash-header-avatar-size: 64px;
$dash-header-font-size: 22px;

$dash-sectionpage-padding: $medium-padding + $small-padding;

$dash-navigation-max-size: 220px;
$dash-navigation-size-with-margin: $dash-navigation-max-size + $dash-dashboard-margin-size;
$dash-navigation-closed-size: 50px;
$dash-navigation-closed-size-with-margin: $dash-navigation-closed-size +
  $dash-dashboard-margin-size;
$dash-navigation-close-height: 28px;

$dash-site-content-main-size: $dash-dashboard-item-default-width * 2 +
  $dash-dashboard-margin-size;
$dash-site-content-max-size: $dash-site-content-main-size + $dash-navigation-size-with-margin;
$dash-site-content-main-expanded-size: $dash-site-content-max-size -
  $dash-navigation-closed-size-with-margin;

$dash-site-content-wide-main-size: $dash-site-content-main-size +
  $dash-dashboard-item-default-width;
$dash-site-content-wide-max-size: $dash-site-content-wide-main-size +
  $dash-navigation-size-with-margin;
$dash-site-content-wide-main-expanded-size: $dash-site-content-wide-max-size -
  $dash-navigation-closed-size-with-margin;

$dash-site-content-min-height: calc(100vh - #{$dash-header-height});

$dash-content-titlebar-height: 60px;
$dash-content-loader-width: 196px;
$dash-content-loader-height: 192px;
$dash-content-loader-anim: loaderAnim;

$dash-search-height: 44px;
$dash-big-icon-size: 44px;
$dash-default-icon-size: 24px;
$dash-search-button-size: 18px;
$dash-form-default-button-height: $dash-search-height;
$dash-form-default-textarea-height: 80px;
$dash-form-default-range-height: 30px;
$dash-form-default-range-thumb-height: 22px;
$dash-form-checkbox-size: 20px;
$dash-form-checkbox-transition-ms: 80ms;
$dash-form-animation-spinner-name: buttonSpinner;
$dash-form-animation-spinner-duration: 800ms;
$dash-form-multi-height: 78px;

$dash-paginator-button-width: 125px;
$dash-paginator-button-height: 28px;

$dash-content-avatar-size: 60px;
$dash-content-button-close-size: $dash-navigation-close-height;
$dash-content-dt-width: 120px;
$dash-venue-dt-width: 200px;
$dash-venue-dt-height: 26px;

$dash-settings-handle-width: 40px;

$dash-venue-maps-width: 320px;
$dash-venue-maps-height: 200px;
$dash-venue-sortable-handle: 44px;
$dash-venue-sortable-label-offset: 68px;
$dash-venue-sortable-contents-width: 780px;

$dash-aside-max-width: $dash-navigation-max-size;
$dash-aside-section-max-width: calc(100% - #{$dash-aside-max-width + $default-margin * 2});
$dash-aside-default-font-size: 12px;
$dash-chat-avatar-size: 32px;

$dash-campaign-action-button-no: 7;
$dash-campaign-action-button-offset: $very-small-margin;
$dash-campaign-action-button-width: 54px;
$dash-campaign-action-button-canvas-size: $dash-campaign-action-button-width +
  $very-small-margin;
$dash-campaign-action-button-animation-name: pulse-animation;
$dash-campaign-warning-animation-name: warning-animation;

$dash-modal-max-width: $dash-site-content-main-size;
$dash-modal-icon-size: 44px;
