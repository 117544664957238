@import '../../styles/common';

:root {
  --pseudo-margin: -50px;
  --pseudo-transform: 0.8;
}

.footer-container {
  overflow: hidden;
}

FOOTER.blk-footer {
  position: relative;
  bottom: 0;
  width: 100%;
  z-index: 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include mobile {
    margin-bottom: 0;
  }

  .location {
    text-transform: uppercase;
  }

  //@include flexbox(flex, row, nowrap, center, flex-start, stretch);
  color: $white;
  background-color: var(--data-main-color-general);
  border-top: 2px solid var(--data-separator-color-general);

  @include mobile {
    flex-direction: column;
    position: relative;
    z-index: 1;
  }

  h3 {
    @include font-size(14px);
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: $font-weight-normal;
    margin-bottom: 16px;
    min-height: 24px;
  }

  HR {
    border-left: 1px solid $border-grey;
    margin: 4em 0px 4em;
    @include mobile {
      display: none;
    }
  }

  DIV.content {
    position: relative;
    z-index: 2;
    @include flexbox(flex, row, nowrap, space-between, normal, stretch);
    @include mobile {
      flex-direction: column;
    }
    & > DIV {
      flex-grow: 1;
      max-width: 100%;
      padding: 7em 0px 0px 0px;
      text-align: center;
      @include flexbox(flex, row, wrap, space-around, normal, stretch);
      @include mobile {
        padding: 0;
        max-width: 100%;
        flex-direction: column;
      }
    }
  }
  DIV.item-box {
    text-align: left;
    max-width: 50%;
    flex-grow: 1;
    flex-direction: column;
    padding: 0 2em;
    letter-spacing: 0.1em;
    flex-basis: 25%;
    @include font-size(12px);
    @include mobile {
      max-width: 100%;
      padding: 0em 2em 0 2em;
    }
    button {
      color: $white;
    }
    .ic-first-text {
      position: relative;
      IMG {
        position: absolute;
        left: -23px;
        width: 1.4em;
      }
    }
  }
  UL {
    margin-bottom: convert-rem(16);
    LI {
      margin-bottom: convert-rem(12);
      A {
        position: relative;
        &:hover {
          color: #e3c886;
        }
        .icon-format {
          position: absolute;
          font-size: convert-rem(23);
        }
      }
    }
    .ssl_image {
      width: 500px;
      max-width: 100%;
      height: auto;
      max-height: 500px;
    }

    .ssl_image_mobile {
      width: 500px;
      max-width: 80%;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-self: center;
    }
  }
  UL.social-group {
    max-width: 6rem;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    @include flexbox(flex, row, nowrap, center, center, unset);
    margin-top: 2em;
    LI {
      margin-right: auto;
    }
    IMG {
      width: 1em;
      &.linkedin {
        font-size: 1.2rem;
        color: white;
      }
    }
    LI:last-child IMG {
      width: 1.5em;
    }
    a {
      display: block;
      margin: 0 8px;
    }
  }

  .payments-accepted {
    @include mobile {
      gap: 1.5vw;
      margin-top: 5vw;
      border-radius: 0.7vw;
    }
    // background: #192038;
    border-radius: 0.2vw;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    // padding: 0.8vw;
    gap: 0.5vw;
    margin-top: 2vw;
    @include font-size(14px);
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: $font-weight-normal;
    min-height: 24px;

    & > .payments-accepted-images {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      & > img {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      & > a {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          @include mobile {
            width: 10vw;
          }
          width: 3vw;
        }
      }
    }
  }
}

.footer-logo-container {
  display: flex;
  justify-content: center;
}

.footer-logo {
  margin-bottom: -0.8rem;
  z-index: 10;
  width: 350px;
  max-width: 90vw;
  height: auto;
}
